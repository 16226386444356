.infoWin {
  border: solid 1px silver;
  background: url('./panda.jpg') no-repeat center;
  background-size: 100% 100%;
  color:rgba(0,0,0,0)
}

div.infoWin-top {
  position: relative;
  background: none repeat scroll 0 0 rgba(0,0,0,0);
  border-bottom: 1px solid #CCC;
  border-radius: 5px 5px 0 0;
}

div.infoWin-top div {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 31px;
  padding: 0 10px;
}

div.infoWin-top img {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-duration: 0.25s;
}

div.infoWin-top img:hover {
  box-shadow: 0px 0px 5px #000;
}

div.infoWin-middle {
  font-size: 12px;
  padding: 6px;
  line-height: 20px;
}

div.infoWin-bottom {
  height: 0px;
  width: 100%;
  clear: both;
  text-align: center;
}

div.infoWin-bottom img {
  position: relative;
  z-index: 104;
}

.infoWin-middle img {
  float: left;
  margin-right: 6px;
}